import { useContext } from 'react'
// import { FlatButton } from '../../components/Button/Button'
// import useResource from '../../hooks/resource.hooks'
// import BsGithub from '../../icons/Github.icon'
// import BSLinkedin from '../../icons/Linkedin.icon'
import styles from './Footer.module.css'
// import data from './Footer.resource.json'
import { GameContext } from '../../providers/GameProvider'

export default function Footer() {
  //const resource = useResource(data)
  const { started } = useContext(GameContext)!
  // const goToGithub = () => window.open('https://github.com/Olivergg1', '_blank')
  // const goToLinkedin = () =>
  //   window.open('https://www.linkedin.com/in/olivergustaf/', '_blank')

  return !started ? (
    <footer id={styles.footer}>
      {/* <p id={styles.by}>{resource('by')}</p> */}
      {/* <div id={styles.links}>
        <FlatButton id={styles.github} noheight onClick={goToGithub}>
          <BsGithub />
        </FlatButton>
        <FlatButton id={styles.linkedin} noheight onClick={goToLinkedin}>
          <BSLinkedin />
        </FlatButton>
      </div> */}
    </footer>
  ) : null
}

import Player from '../../../../classes/Player.class'
import useResource from '../../../../hooks/resource.hooks'
import Swap from '../../../../icons/Swap.icon'
import styles from './PairAnnouncement.module.css'
import data from './PairAnnouncement.resource.json'

interface PairAnnouncementProps {
  pair: [Player, Player]
  skip: () => void
}

export default function PairAnnouncement({
  pair,
  skip,
}: PairAnnouncementProps) {
  const [player1, player2] = pair
  const resource = useResource(data)

  return (
    <article role="button" id={styles.announcement} onClick={skip}>
      <Swap width={64} height={64} />
      <h3>{resource('switch')}</h3>
      <div id={styles.message}>
        <p className={styles.player}>{player1.name}</p>
        <p>{resource('and')}</p>
        <p className={styles.player}>{player2.name}</p>
      </div>
      {/* <p className="blink">Klicka för att fortsätta</p> */}
    </article>
  )
}

import Player from '../../../../../../classes/Player.class'
import PlayerListItem from '../PlayerListItem/PlayerListItem'
import './PlayerList.css'

interface PlayerListProps {
  players: Player[]
}

export default function PlayerList({ players }: PlayerListProps) {
  return (
    <div id="playerList" role="list">
      {players.map(player => (
        <PlayerListItem key={player.id} player={player} />
      ))}
    </div>
  )
}

import { IconProps } from '.'

export default function Ice(props: IconProps) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      className="iconify iconify--twemoji"
      preserveAspectRatio="xMidYMid meet"
      fill="#000000"
      {...props}>
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path
          fill="#F5F8FA"
          d="M4.66 7.479c1.928.786 5.193 1.693 8.25 2.556c2.76.78 4.727 1.8 4.727 4.279c0 1.126-.136 4.519-.185 8.533c.41-.104.791-.153 1.196-.117l-.001-.134c-.038-4.321-.179-8.044-.178-9.239c.003-2.529 1.817-2.208 4.667-2.996c1.297-.359 7.232-1.686 9.209-2.103c1.658-.35 1.012-1.059.494-1.617c-.034.107-.147.202-.383.273c-1.51.453-10.716 2.236-12.941 2.859c-1.569.439-3.591-.367-6.007-1.349c-2.416-.981-8.53-2.416-9.738-2.869a1.547 1.547 0 0 1-.57-.362c-.094.052-.19.103-.278.161c.066-.003.12-.011.19-.012c.477-.008-.195 1.427 1.548 2.137z"></path>
        <path
          fill="#ADD3E0"
          d="M1.009 10.872c.096 2.553.356 9.03.119 11.941c-.157 1.923.24 3.361.996 4.403c.204-.333.64-.608 1.36-.736c3.752-.669 9.878-2.385 12.344-3.136c.654-.199 1.16-.378 1.625-.496c.049-4.014.185-7.407.185-8.533c0-2.479-1.967-3.499-4.727-4.279c-3.058-.864-6.323-1.771-8.251-2.557c-1.743-.71-1.071-2.145-1.548-2.138c-.069.001-.123.01-.19.012C1.293 6.432.919 8.508 1.009 10.872z"></path>
        <path
          fill="#C1E1EA"
          d="M33.208 27.8c.425.097.866.309 1.268.583c.438-.907.341-2.082.275-3.431c-.119-2.436.059-10.099.238-13.604c.148-2.909-.822-4.267-2.167-4.907a.254.254 0 0 1 .019.2c.517.558 1.163 1.267-.494 1.617c-1.978.417-7.912 1.745-9.209 2.103c-2.85.788-4.664.467-4.667 2.996c-.001 1.195.14 4.919.178 9.239l.001.134c.47.042.98.194 1.638.526c1.367.691 10.883 4.079 12.92 4.544z"></path>
        <path
          fill="#D2ECF3"
          d="M13.509 8.424c2.416.981 4.437 1.788 6.007 1.349c2.225-.622 11.431-2.406 12.941-2.859c.237-.071.35-.166.383-.273a4.563 4.563 0 0 1-.286-.327a4.983 4.983 0 0 0-.595-.194c-2.554-.654-8.436-2.495-10.931-3.386c-1.977-.706-4.487-.591-6.594-.119c-2.34.524-7.081 1.706-9.446 2.02c-.71.094-1.296.289-1.788.559c.138.139.32.268.57.362c1.209.452 7.323 1.886 9.739 2.868z"></path>
        <path
          opacity=".5"
          fill="#CFE1EA"
          d="M18.648 22.73a3.517 3.517 0 0 0-1.196.117l-.006.493c-.048 4.716.194 8.644.127 9.281c-.046.438-.315.814-.717 1.072c.821.1 1.641.088 2.424-.042c-.451-.385-.71-.998-.771-1.608c-.064-.655.176-4.556.139-9.313z"></path>
        <path
          fill="#9BC2D4"
          d="M17.573 32.621c.067-.637-.176-4.564-.127-9.281l.006-.493c-.465.117-.971.297-1.625.496c-2.466.751-8.592 2.467-12.344 3.136c-.719.128-1.156.404-1.36.736a4.834 4.834 0 0 0 2.152 1.657c2.079.832 6.772 2.495 9.743 3.98c.9.45 1.868.721 2.838.84c.403-.257.671-.633.717-1.071zm14.505-2.62c1.347-.331 2.046-.888 2.398-1.618c-.402-.274-.843-.486-1.268-.583c-2.037-.465-11.554-3.853-12.922-4.544c-.658-.332-1.168-.485-1.638-.526c.037 4.758-.203 8.658-.138 9.313c.061.611.32 1.223.771 1.608a7.529 7.529 0 0 0 1.451-.382c2.019-.773 8.197-2.496 11.346-3.268z"></path>
        <path
          fill="#C1E1EA"
          d="M7.571 18.69c-3.911 3.322-5.322 9.72-5.206 6.824c.096-2.391-.017-9.487-.006-12.439c.009-2.491.416-4.634 3.725-3.095c2.538 1.18 7.2 2.114 9.384 3.59c1.411.954-2.464.505-7.897 5.12z"></path>
        <path
          fill="#D2ECF3"
          d="M30.169 15.439c2.65 1.793 3.569 5.587 3.497 3.781c-.06-1.491.006-4.939 0-6.779c-.006-1.553.19-2.874-1.997-2.245c-1.88.54-5.124.847-6.486 1.767c-.88.594 1.408 1.056 4.986 3.476z"></path>
      </g>
    </svg>
  )
}

import { useCallback, useEffect, useMemo, useState } from 'react'
import useLocale from './locale.hooks'
import { getPrompts, Prompt } from '../services/Prompt.service'
import { shuffle } from '../helpers/shuffle.helper'

export default function usePrompts() {
  const { language } = useLocale()!
  const [current, setCurrent] = useState(0)
  const [prompts, setPrompts] = useState<Prompt[]>([])
  const [loading, setLoading] = useState(true)

  const fetchPrompts = useCallback(async () => {
    setLoading(true)
    const prompts = await getPrompts(language)
    shuffle(prompts)
    setPrompts(prompts)
    setLoading(false)
  }, [language])

  useEffect(() => {
    fetchPrompts()
  }, [fetchPrompts])

  const next = useCallback(
    function () {
      if (prompts.length === 0) return

      const nextIndex = current + 1
      setCurrent(nextIndex)
    },
    [current, prompts]
  )

  const prompt = prompts[current]

  const isFinalPromptReached = useMemo(
    () => !loading && current >= prompts.length,
    [loading, current, prompts.length]
  )

  return { prompt, index: current, next, loading, isFinalPromptReached }
}

import GameHandler, { GameComponentProps } from '../../classes/Game.class'
import styles from './Ryggar.module.css'
import { useCallback, useEffect, useRef, useState } from 'react'
import { createPairwiseTeams } from '../../helpers/team.helper'
import usePrompts from '../../hooks/prompt.hooks'
import useWildcard from '../../hooks/wildcard.hooks'
import useGameEndModal from '../../modals/End/End'
import { usePlayers } from '../../hooks/game.hooks'
import PairAnnouncement from './components/PairAnnouncement/PairAnnouncement'
import Wildcard from './components/Wildcard/Wildcard'
import PromptView from './components/PromptView/PromptView'

export const RyggarGameHandler = new GameHandler('Ryggar', Ryggar, 3)

export default function Ryggar({ ...rest }: GameComponentProps) {
  const players = usePlayers()
  const { index, prompt, isFinalPromptReached, next } = usePrompts()!
  const [pairIndex, setPairIndex] = useState(0)
  const [shouldDisplayWildCard, hide] = useWildcard(10, prompt)
  const [shouldAnnounceNewPair, setShouldAnnounceNewPair] = useState(true)
  const { show } = useGameEndModal()

  // Genereate teams from players array
  const teamsRef = useRef(createPairwiseTeams(players))
  const teams = teamsRef.current
  const pair = teams[pairIndex]

  const handleNewTeam = useCallback(() => {
    setPairIndex(n => (n + 1) % teams.length)
    setShouldAnnounceNewPair(true)
  }, [teams])

  useEffect(() => {
    if (isFinalPromptReached) show()
  }, [isFinalPromptReached, show])

  useEffect(() => {
    if (index % 10 === 0) {
      handleNewTeam()
    }
  }, [index, handleNewTeam])

  function hideAnnouncement() {
    setShouldAnnounceNewPair(false)
  }

  return (
    <div id={styles.ryggar}>
      {(function () {
        if (isFinalPromptReached) return null
        if (shouldDisplayWildCard && index !== 0)
          return <Wildcard skip={hide} />
        if (shouldAnnounceNewPair)
          return <PairAnnouncement skip={hideAnnouncement} pair={pair} />

        return <PromptView prompt={prompt} pair={pair} next={next} />
      })()}
    </div>
  )
}

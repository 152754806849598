import { HTMLAttributes, useContext } from 'react'
import { FlatButton } from '../Button/Button'
import BsSettings from '../../icons/Settings.icon'
import { GameContext } from '../../providers/GameProvider'
import styles from './View.module.css'
import useSettingsModal from '../../modals/Settings/Settings.modal'

interface ViewProps extends HTMLAttributes<HTMLDivElement> {
  title?: string
  onTitleClick?: () => unknown
}

export default function View({
  children,
  title,
  onTitleClick,
  ...rest
}: ViewProps) {
  const { started } = useContext(GameContext)!
  const { show } = useSettingsModal()

  return (
    <div {...rest} className={styles.view}>
      <header>
        <h1 className={styles.viewTitle} onClick={onTitleClick}>
          {title}
        </h1>
        <FlatButton
          noheight
          id={styles.settings}
          onClick={show}
          disabled={started}>
          <BsSettings width={24} height={24} />
        </FlatButton>
      </header>
      {children}
    </div>
  )
}

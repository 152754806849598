import {
  ComponentType,
  createContext,
  PropsWithChildren,
  useState,
} from 'react'
import Player from '../classes/Player.class'
import { Spice, Spiciness } from '../config/spiciness.config'
import GameHandler, { GameComponentProps } from '../classes/Game.class'
import { useStored } from '../hooks/stored.hooks'
import Games, { DEFAULT_GAME_KEY } from '../config/game.config'

interface IGameContext {
  started: boolean
  canStart: boolean
  players: Player[]
  start: () => void
  finish: () => void
  addPlayer: (player: string) => void
  removePlayer: (player: Player) => void
  spice: Spice
  setSpiciness: (spice: number) => unknown
  Game?: ComponentType<GameComponentProps>
  handler?: GameHandler | null
  setHandler: (game: string) => unknown

  accepted: boolean
  setAccepted: (value: boolean) => unknown

  seenTutorial: boolean
  setSeenTutorial: () => unknown
}

export const GameContext = createContext<IGameContext | null>(null)

export default function GameProvider(props: PropsWithChildren) {
  const [started, setStarted] = useState(false)
  const [players, setPlayers] = useState<Player[]>([])
  const [spice, setSpice] = useState<Spice>(Spiciness[1])

  const hasAccepted = useStored('accepted')
  const accepted = hasAccepted.stored === 'true'

  const storedHandler = useStored('handler', DEFAULT_GAME_KEY)
  const handler = Games[storedHandler.stored]

  const tutorial = useStored(handler.name, 'false')
  const seenTutorial = tutorial.stored === 'true'
  const setSeenTutorial = () => tutorial.setStored('true')

  const canStart = players.length >= (handler?.minPlayers ?? 0)

  function setHandler(game: string) {
    storedHandler.setStored(game)
  }

  function start() {
    setStarted(() => true)
  }

  function finish() {
    setStarted(() => false)
  }

  function addPlayer(name: string) {
    const player = new Player(name)
    setPlayers(current => [...current, player])
  }

  function removePlayer(player: Player) {
    setPlayers(current => current.filter(p => p.id !== player.id))
  }

  function setSpiciness(spiceness: number) {
    setSpice(c => Spiciness.find(spice => spice.value === spiceness) ?? c)
  }

  const Game = handler?.Game

  return (
    <GameContext.Provider
      value={{
        spice,
        setSpiciness,
        started,
        players,
        start,
        finish,
        addPlayer,
        removePlayer,
        canStart,
        handler,
        setHandler,
        Game,
        accepted,
        setAccepted: (value: boolean) =>
          hasAccepted.setStored(value.toString()),
        seenTutorial,
        setSeenTutorial,
      }}>
      {props.children}
    </GameContext.Provider>
  )
}

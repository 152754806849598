import { useEffect, useRef, useState } from 'react'
import clamp from '../helpers/clamp.helper'

export default function useWildcard(rate: number, update: any) {
  const [shouldShow, setShouldShow] = useState(false)

  const rateRef = useRef(clamp(rate, 0, 100))
  const actualRate = rateRef.current

  useEffect(() => {
    const result = Math.random() * 100 < actualRate
    setShouldShow(result)
  }, [rate, update, actualRate])

  function hide() {
    setShouldShow(false)
  }

  return [shouldShow, hide] as [boolean, () => void]
}

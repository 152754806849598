import { ReactNode } from 'react'
import Ice from '../icons/Ice.icon'
import Devil from '../icons/Devil.icon'
import Adults from '../icons/Adults.icon'

export interface Spice {
  value: number
  name: string
  icon: ReactNode
}

const spiciness = [
  {
    value: 0,
    name: 'Ice breaker',
    icon: <Ice />,
  },
  {
    value: 1,
    name: 'Nära vänner',
    icon: <Devil />,
  },
  {
    value: 2,
    name: 'Vuxenfrågor',
    icon: <Adults />,
  },
] satisfies Spice[]

export const Spiciness = spiciness

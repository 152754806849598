import { useCallback, useContext } from 'react'
import Games from '../config/game.config'
import not from '../utils/not.util'
import { GameContext } from '../providers/GameProvider'

export default function useGameHandler() {
  const { handler, setHandler } = useContext(GameContext)!

  const update = useCallback(
    (game: string) => {
      if (not(game in Games)) return

      setHandler(game)
    },
    [setHandler]
  )

  return { handler, setHandler: update }
}

import Themes from '../../../config/themes.config'
import { useModalManager } from '../../../hooks/modal.hooks'
import useTheme from '../../../hooks/theme.hooks'
import Select, { Closeable, SelectOption } from '../Select'

export default function ThemeSelect({ closeable }: Closeable) {
  const { key, updateTheme } = useTheme()!
  const { close } = useModalManager()

  function setTheme(theme: string) {
    updateTheme(theme)
    closeable && close()
  }

  const themeOptions = Object.keys(Themes).map(theme => {
    const actual = Themes[theme]

    return new SelectOption(
      () => <>{actual.name}</>,
      theme,
      theme === key,
      theme => setTheme(theme)
    )
  })

  return <Select items={themeOptions} />
}

import GameHandler from '../classes/Game.class'
import { FreeplayGameHandler } from '../games/Frispel/Frispel'
import { RyggarGameHandler } from '../games/Ryggar/Ryggar'
import { SnuslekenGameHandler } from '../games/Snusleken/Snusleken'

const GameModes = {
  FREE: 'Frispel',
  RYGGAR: 'Ryggar',
  SNUS: 'Snusleken',
} as const

export const Games = {
  [GameModes.FREE]: FreeplayGameHandler,
  [GameModes.RYGGAR]: RyggarGameHandler,
  [GameModes.SNUS]: SnuslekenGameHandler,
} as Record<string, GameHandler>

export const DEFAULT_GAME_KEY = GameModes.RYGGAR
export const DEFAULT_GAME = Games[DEFAULT_GAME_KEY]

export default Games

import View from '../../../View/View'
import useResource from '../../../../hooks/resource.hooks'
import data from './Main.resource.json'
import styles from './Main.module.css'
import Loader from '../../../../layout/Loader/Loader'
import { useContext, useEffect, useState } from 'react'
import { FlatButton } from '../../../Button/Button'
import useHelpModal from '../../../../modals/Help/Help.modal'
import useExitModal from '../../../../modals/Exit/Exit.modal'
import { GameContext } from '../../../../providers/GameProvider'
import useGameHandler from '../../../../hooks/gameHandler.hooks'
import BsInfo from '../../../../icons/Info.icon'

export default function Main() {
  const resource = useResource(data)
  const [loading, setLoading] = useState(true)
  const { show: showInfo } = useHelpModal()
  const { show: showExit } = useExitModal()
  const { Game } = useContext(GameContext)!
  const { handler } = useGameHandler()

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1500)
  }, [])

  return (
    <View title={handler?.name} onTitleClick={showExit}>
      <main id={styles.main}>
        {loading && <Loader text={resource('loading')} />}
        {!loading && Game && <Game />}
      </main>
      <footer>
        <FlatButton bold id={styles.help} onClick={showInfo}>
          <BsInfo />
          {resource('help_needed')}
        </FlatButton>
      </footer>
    </View>
  )
}

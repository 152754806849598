import { useRef } from 'react'
import useResource from '../../../../hooks/resource.hooks'
import Crazy from '../../../../icons/Crazy.icon'
import styles from './Wildcard.module.css'
import data from './Wildcard.resource.json'

interface WildcardProps {
  skip: () => void
  max?: number
}

export default function Wildcard({ skip, max = 5 }: WildcardProps) {
  const resource = useResource(data)

  function randomSips() {
    return Math.floor(Math.random() * max) + 1
  }

  const sipsRef = useRef(randomSips())
  const sips = sipsRef.current

  return (
    <article role="button" id={styles.wildcard} onClick={skip}>
      <Crazy className="shake" width={64} height={64} />
      <h3>{resource('wildcard')}</h3>
      <p>{resource('todo').replace('?', sips)}</p>
    </article>
  )
}

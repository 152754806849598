import { generateRandomId } from '../helpers/id.helper'

export default class Player {
  readonly id: string
  readonly name: string

  constructor(name: string) {
    this.name = name
    this.id = generateRandomId(6)
  }
}

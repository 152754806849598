import { useCallback, useContext, useEffect } from 'react'
import { GameContext } from '../../providers/GameProvider'
import Main from './components/Main/Main'
import Lobby from './components/Lobby/Lobby'
import styles from './Game.module.css'
import useWelcomeModal from '../../modals/Welcome/Welcome.modal'

export default function Game() {
  const { started } = useContext(GameContext)!

  const { show } = useWelcomeModal()

  const tryDisplayWelcomeMessage = useCallback(() => {
    const first = localStorage.getItem('welcomed')

    if (first) return

    show()

    if (process.env.NODE_ENV === 'production') {
      localStorage.setItem('welcomed', 'true')
    }
  }, [show])

  useEffect(() => {
    tryDisplayWelcomeMessage()
    // eslint-disable-next-line
  }, [])

  return <div id={styles.game}>{started ? <Main /> : <Lobby />}</div>
}

import { createContext, PropsWithChildren } from 'react'
import { useStored } from '../hooks/stored.hooks'

export const LANGUAGES = {
  SV: 'Svenska',
  ES: 'Español',
  EN: 'English',
  DE: 'Deutsch',
  FR: 'Français',
} as const
export const DEFAULT_LANGUAGE: Language = 'SV'

export type Language = keyof typeof LANGUAGES
export type LanguageName = string

interface ILocaleContext {
  language: Language
  name: LanguageName
  setLocale: (language: Language) => unknown
}

export const LocaleContext = createContext<ILocaleContext | null>(null)

export default function LocaleProvider({ children }: PropsWithChildren) {
  const storedLanguage = useStored('locale', DEFAULT_LANGUAGE)

  const language = storedLanguage.stored as Language
  const name = LANGUAGES[language]

  function setLocale(language: Language) {
    storedLanguage.setStored(language)
  }

  return (
    <LocaleContext.Provider value={{ language, name, setLocale }}>
      {children}
    </LocaleContext.Provider>
  )
}

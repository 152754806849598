import { HTMLAttributes } from 'react'
import { FilledButton } from '../Button/Button'
import styles from './Switch.module.css'
import Check from './Check'

interface SwitchProps extends HTMLAttributes<HTMLButtonElement> {
  value: boolean
  onToggle: (value: boolean) => unknown
  label?: string
}

export default function Switch({
  value,
  label,
  onToggle,
  ...rest
}: SwitchProps) {
  const classes = [rest.className, styles.switch].join(' ')

  function handleClick() {
    onToggle(!value)
  }

  return (
    <FilledButton primary {...rest} className={classes} onClick={handleClick}>
      <Check checked={value} />
      <h3>{label}</h3>
    </FilledButton>
  )
}

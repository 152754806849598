import { ComponentType } from 'react'
import clamp from '../helpers/clamp.helper'

export interface GameComponentProps {}

const MAX_PLAYERS = 24 // move to a constants folder and file

export default class GameHandler {
  public readonly Game: ComponentType<GameComponentProps>
  public readonly name: string
  public readonly minPlayers: number
  public readonly isPlayersRequired: boolean

  constructor(
    name: string,
    game: ComponentType<GameComponentProps>,
    minPlayers: number = 4
  ) {
    this.name = name
    this.Game = game
    this.minPlayers = clamp(minPlayers, 0, MAX_PLAYERS)
    this.isPlayersRequired = minPlayers !== 0
  }
}

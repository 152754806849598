import { useModal, useModalManager } from '../../hooks/modal.hooks'
import { FilledButton } from '../../components/Button/Button'
import LanguageSelect from '../../components/Select/Selects/LanguageSelect'
import styles from './Welcome.module.css'
import useResource from '../../hooks/resource.hooks'
import data from './Welcome.resource.json'
import ThemeSelect from '../../components/Select/Selects/ThemeSelect'

function Welcome() {
  const resource = useResource(data)
  const { close } = useModalManager()

  return (
    <div id={styles.message}>
      <LanguageSelect />
      <ThemeSelect />
      <p id={styles.update}>{resource('update')}</p>
      <FilledButton id={styles.proceed} primary onClick={close}>
        <h3>{resource('proceed')}</h3>
      </FilledButton>
    </div>
  )
}

export default function useWelcomeModal() {
  return useModal({
    title: 'Välkommen!',
    element: <Welcome />,
    id: styles.welcome,
    centered: true,
    closeable: false,
  })
}

import {
  ChangeEvent,
  DetailedHTMLProps,
  InputHTMLAttributes,
  MouseEvent,
  ReactNode,
  useRef,
} from 'react'
import './Input.css'
import { FlatButton } from '../Button/Button'
import BsErase from '../../icons/Erase.icon'

interface InputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  onChangeText?: (value: string) => unknown
  onClear?: () => unknown
  clearable?: boolean
}

export default function Input({
  onChangeText,
  onClear,
  clearable,
  value,
  children,
  disabled,
  ...rest
}: InputProps) {
  const classes = ['input', rest.className].join(' ')
  const element = useRef<HTMLInputElement>(null)

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault()

    onChangeText && onChangeText(e.currentTarget.value)
  }

  function clear(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault()

    onClear?.()
  }

  return (
    <div className={'inputContainer'} data-disabled={disabled}>
      <main>
        <input
          ref={element}
          type="text"
          value={value}
          {...rest}
          className={classes}
          onChange={handleChange}
        />
        {clearable && (
          <FlatButton
            id="clearNameInput"
            onClick={clear}
            disabled={!value?.toString().length}>
            <BsErase width={12} height={12} />
          </FlatButton>
        )}
      </main>
      {children}
    </div>
  )
}

interface InputWithButtonProps extends InputProps {
  icon: ReactNode
  disableButton?: boolean
  onPress?: (e: MouseEvent<HTMLButtonElement>) => unknown
}

export function InputWithButtons({
  icon,
  onPress,
  disableButton,
  ...rest
}: InputWithButtonProps) {
  function handlePress(e: MouseEvent<HTMLButtonElement>) {
    onPress?.(e)
  }
  return (
    <Input {...rest}>
      <FlatButton
        noheight
        type="submit"
        id="inputButton"
        disabled={disableButton}
        onClick={handlePress}>
        {icon}
      </FlatButton>
    </Input>
  )
}

import { FormEvent, useContext, useState } from 'react'
import { FilledButton } from '../../../Button/Button'
import PlayerList from './components/PlayerList/PlayerList'
import { GameContext } from '../../../../providers/GameProvider'
import { InputWithButtons } from '../../../Input/Input'
import BsAdd from '../../../../icons/Add.icon'
import './Lobby.css'
import View from '../../../View/View'
import useResource from '../../../../hooks/resource.hooks'
import data from './Lobby.resource.json'
import Ghost from '../../../../icons/Ghost.icon'
import useGameHandler from '../../../../hooks/gameHandler.hooks'
import useStartModal from '../../../../modals/Start/Start.modal'
import GhostSmile from '../../../../icons/GhostSmile.icon'

function NoPlayers() {
  const resource = useResource(data)

  return (
    <div id="noPlayers">
      <Ghost id="ghost" />
      <h3>{resource('no_players')}</h3>
      <p>{resource('no_players_add')}</p>
    </div>
  )
}

function Ready() {
  const resource = useResource(data)

  return (
    <div id="noPlayers">
      <GhostSmile id="ghost" />
      <h3>{resource('ready')}</h3>
      <p>{resource('press_start')}</p>
    </div>
  )
}

export default function Lobby() {
  const { players, addPlayer, accepted, start, canStart } =
    useContext(GameContext)!
  const [name, setName] = useState('')
  const resource = useResource(data)
  const { handler } = useGameHandler()
  const { show } = useStartModal()

  const minPlayers = handler?.minPlayers ?? 0

  const shouldShowAddMorePlayers =
    minPlayers !== 0 && players.length < minPlayers
  const shouldShowEmptyLobby = minPlayers !== 0 && players.length === 0
  const shouldShowSomeMessage = minPlayers === 0
  const shouldShowPlayerList = minPlayers !== 0 && players.length > 0

  function MorePlayersRequiredMessage() {
    const resource = useResource(data)

    return (
      <div id="morePlayers">
        <p>{resource('player_req').replace('?', handler?.minPlayers)}</p>
      </div>
    )
  }

  function clear() {
    setName('')
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()

    addPlayer(name)
    clear()
  }

  function tryStartOrShowStartModal() {
    if (!accepted) return show()

    start()
  }

  return (
    <View title={resource('lobby')}>
      <form onSubmit={handleSubmit}>
        <InputWithButtons
          id="nameInput"
          clearable
          value={name}
          placeholder={resource('add_player')}
          onChangeText={setName}
          onClear={clear}
          icon={<BsAdd width={24} height={24} />}
          disabled={handler?.isPlayersRequired !== true}
          disableButton={name.trim().length < 3}
        />
      </form>
      {shouldShowPlayerList && <PlayerList players={players} />}
      {shouldShowEmptyLobby && <NoPlayers />}
      {shouldShowSomeMessage && <Ready />}
      {shouldShowAddMorePlayers && <MorePlayersRequiredMessage />}
      <FilledButton
        bold
        id="startGame"
        disabled={!canStart}
        onClick={tryStartOrShowStartModal}>
        {resource('start')}
      </FilledButton>
    </View>
  )
}

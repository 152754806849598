import { IconProps } from '.'

export default function Adults(props: IconProps) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      className="iconify iconify--twemoji"
      preserveAspectRatio="xMidYMid meet"
      {...props}>
      <path
        d="M34.999 17.999c0 9.389-7.611 17-17 17s-17-7.611-17-17s7.611-17 17-17s17 7.611 17 17"
        fill="#000000"></path>
      <path
        fill="#F5F8FA"
        d="M9.521 12.245H7.85c-1.358 0-1.924-.991-1.924-1.953c0-.99.707-1.952 1.924-1.952h4.019c1.217 0 1.896.876 1.896 2.007v16.104c0 1.414-.906 2.207-2.122 2.207c-1.216 0-2.122-.793-2.122-2.207V12.245zm7.307 10.13c0-2.264 1.245-3.934 3.027-4.895c-1.33-.963-2.15-2.265-2.15-4.047c0-3.312 2.745-5.434 6.112-5.434c3.283 0 6.14 2.093 6.14 5.434c0 1.583-.791 3.17-2.178 4.047c1.924.96 3.027 2.715 3.027 4.895c0 3.934-3.197 6.451-6.989 6.451c-3.906 0-6.989-2.658-6.989-6.451zm4.413-.283c0 1.443.849 2.832 2.575 2.832c1.612 0 2.576-1.389 2.576-2.832c0-1.783-1.02-2.83-2.576-2.83c-1.641 0-2.575 1.246-2.575 2.83zm.538-8.206c0 1.274.736 2.151 2.037 2.151c1.302 0 2.066-.877 2.066-2.151c0-1.217-.736-2.151-2.066-2.151c-1.33 0-2.037.934-2.037 2.151z"></path>
      <path
        fill="#DD2E44"
        d="M18 0C8.059 0 0 8.06 0 18c0 9.941 8.059 18 18 18s18-8.059 18-18c0-9.94-8.059-18-18-18zm16 18c0 3.969-1.453 7.592-3.845 10.389L7.612 5.845A15.927 15.927 0 0 1 18 2c8.837 0 16 7.164 16 16zM2 18c0-3.968 1.453-7.591 3.844-10.387l22.543 22.543A15.921 15.921 0 0 1 18 34C9.164 34 2 26.837 2 18z"></path>
    </svg>
  )
}

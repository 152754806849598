import GameHandler, { GameComponentProps } from '../../classes/Game.class'

export const SnuslekenGameHandler = new GameHandler('Snusleken', Snusleken, 8)

function Snusleken({ ...rest }: GameComponentProps) {
  return (
    <div>
      <h1>Snusleken</h1>
    </div>
  )
}

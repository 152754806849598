import useLocale from '../../../hooks/locale.hooks'
import { useModalManager } from '../../../hooks/modal.hooks'
import { Language, LANGUAGES } from '../../../providers/LocaleProvider'
import Select, { Closeable, SelectOption } from '../Select'
import styles from './Selects.module.css'

export default function LanguageSelect({ closeable }: Closeable) {
  const { setLocale, ...locale } = useLocale()!
  const { close } = useModalManager()

  function updateLocale(language: Language) {
    setLocale(language as Language)
    closeable && close()
  }

  const languageOptions = Object.entries(LANGUAGES).map(([language, name]) => {
    const actual = language as Language

    return new SelectOption(
      () => (
        <>
          <img
            className={styles.flag}
            src={`${
              process.env.PUBLIC_URL
            }/flags/${language.toLowerCase()}.svg`}
            alt=""
          />
          {name}
        </>
      ),
      actual,
      locale.language === actual,
      language => updateLocale(language)
    )
  })

  return <Select items={languageOptions} />
}

import {
  HTMLAttributes,
  Key,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FilledButton } from '../Button/Button'
import styles from './Select.module.css'
import BsCaret from '../../icons/Caret.icon'

export class SelectOption<T> {
  readonly element
  readonly value
  readonly selected
  readonly onOptionSelected

  constructor(
    element: () => JSX.Element,
    value: T,
    selected: boolean,
    onOptionSelected: (value: T) => unknown
  ) {
    this.element = element
    this.value = value
    this.selected = selected
    this.onOptionSelected = onOptionSelected
  }
}

interface SelectItemProps<T> {
  item: SelectOption<T>
}

function SelectItem<T>({ item, ...rest }: SelectItemProps<T>) {
  return (
    <FilledButton
      bold
      className={styles.option}
      onClick={() => item.onOptionSelected(item.value)}>
      {item.element()}
    </FilledButton>
  )
}

function SelectOptions<T>({
  items,
  ...rest
}: PropsWithChildren & { items: SelectOption<T>[] }) {
  return (
    <ul {...rest} className={styles.options}>
      {items.map(item => (
        <SelectItem key={item.value as Key} item={item} />
      ))}
    </ul>
  )
}

export interface Closeable extends PropsWithChildren {
  closeable?: boolean
}

interface SelectProps<T> extends HTMLAttributes<HTMLUListElement>, Closeable {
  items: SelectOption<T>[]
  fallback?: string
}

export default function Select<T>({
  items,
  fallback,
  ...rest
}: SelectProps<T>) {
  const [showOptions, setShowOptions] = useState(false)
  const element = useRef<HTMLDivElement>(null)

  const options = items.filter(item => !item.selected)
  const selected = items.find(item => item.selected)

  function toggle() {
    setShowOptions(c => !c)
  }

  useEffect(() => {
    setShowOptions(false)
  }, [selected])

  useEffect(() => {
    function handleCloseOnClick(e: Event) {
      if (element.current && !element.current.contains(e.target as Node))
        setShowOptions(false)
    }

    window.addEventListener('click', handleCloseOnClick)

    return () => window.removeEventListener('click', handleCloseOnClick)
  }, [])

  return (
    <div ref={element} className={styles.select}>
      <FilledButton bold className={styles.selected} primary onClick={toggle}>
        {selected?.element()}
        {!selected && fallback}
        <BsCaret
          width={12}
          height={12}
          id={styles.arrow}
          className={showOptions ? styles.flip : ''}
        />
      </FilledButton>
      {showOptions && <SelectOptions items={options} />}
    </div>
  )
}

import { HTMLAttributes } from 'react'
import './Button.css'

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  filled?: boolean
  disabled?: boolean
  noheight?: boolean
  bold?: boolean
  type?: 'button' | 'submit'
}

export default function Button({
  children,
  disabled,
  noheight,
  type = 'button',
  bold,
  ...rest
}: ButtonProps) {
  const classes = [
    rest.className,
    'button',
    noheight && 'noheight',
    bold && 'bold',
  ].join(' ')

  return (
    <button type={type} disabled={disabled} {...rest} className={classes}>
      {children}
    </button>
  )
}

interface FilledButtonProps extends ButtonProps {
  primary?: boolean
}

export function FilledButton({
  children,
  primary,
  ...rest
}: FilledButtonProps) {
  const classes = ['filled', primary && 'primary', rest.className].join(' ')

  return (
    <Button {...rest} className={classes}>
      {children}
    </Button>
  )
}

export function FlatButton({ children, ...rest }: ButtonProps) {
  const classes = ['flat', rest.className].join(' ')

  return (
    <Button {...rest} className={classes}>
      {children}
    </Button>
  )
}

import Player from '../classes/Player.class'
import { shuffle } from './shuffle.helper'

export function createPairwiseTeams(players: Player[]) {
  const teams: [Player, Player][] = []

  for (let i = 0; i < players.length; i++) {
    for (let j = i + 1; j < players.length; j++) {
      teams.push([players[i], players[j]])
    }
  }

  shuffle(teams)

  return teams
}

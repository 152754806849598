import { useCallback, useMemo } from 'react'
import { DEFAULT_LANGUAGE, Language } from '../providers/LocaleProvider'
import useLocale from './locale.hooks'

type ResourceLike = Record<string, any> | Record<string, any>[]
type ResourceKey = Language | string | number
interface Resource extends Record<ResourceKey, ResourceLike> {}

export default function useResource<T extends Resource>(data: T) {
  const { language } = useLocale()!

  const actual = useMemo(() => {
    return data[language] ?? data[DEFAULT_LANGUAGE]
  }, [data, language])

  return useCallback(
    (key: ResourceKey, fallback?: string) => {
      if (Array.isArray(actual)) return actual

      return actual[key] ?? fallback
    },
    [actual]
  )
}

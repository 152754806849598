import { useModal, useModalManager } from '../../hooks/modal.hooks'
import { FilledButton } from '../../components/Button/Button'
import styles from './Help.module.css'
import useResource from '../../hooks/resource.hooks'
import data from './Help.resource.json'

function Help() {
  const resource = useResource(data)
  const { close } = useModalManager()

  return (
    <div id={styles.message}>
      <div id={styles.coming}>
        <p>{resource('coming')}</p>
      </div>

      <FilledButton id={styles.proceed} primary onClick={close}>
        <h3>{resource('proceed')}</h3>
      </FilledButton>
    </div>
  )
}

export default function useHelpModal() {
  const resource = useResource(data)

  return useModal({
    title: resource('how_to'),
    element: <Help />,
    id: styles.help,
  })
}

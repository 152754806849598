import { useContext, useEffect } from 'react'
import GameHandler, { GameComponentProps } from '../../classes/Game.class'
import usePrompts from '../../hooks/prompt.hooks'
import styles from './Frispel.module.css'
import useGameEndModal from '../../modals/End/End'
import Tutorial from './Tutorial/Tutorial'
import { GameContext } from '../../providers/GameProvider'

function Frispel({ ...rest }: GameComponentProps) {
  const { prompt, next, isFinalPromptReached } = usePrompts()
  const { show } = useGameEndModal()
  const { seenTutorial } = useContext(GameContext)!

  useEffect(() => {
    if (isFinalPromptReached) show()
  }, [isFinalPromptReached, show])

  return (
    <>
      {!seenTutorial && <Tutorial />}
      {seenTutorial && (
        <div id={styles.frispel} onClick={next}>
          <h3>{prompt?.content}</h3>
        </div>
      )}
    </>
  )
}

export const FreeplayGameHandler = new GameHandler('Frispel', Frispel, 0)

import useResource from '../../hooks/resource.hooks'
import Button, { FilledButton } from '../../components/Button/Button'
import styles from './End.module.css'
import data from './End.resource.json'
import { useModal, useModalManager } from '../../hooks/modal.hooks'
import { useContext } from 'react'
import { GameContext } from '../../providers/GameProvider'

function ContinueModal() {
  const resource = useResource(data)
  const { close } = useModalManager()
  const { finish } = useContext(GameContext)!

  function finishGame() {
    finish()
    close()
  }

  function openBuyMeACoffee() {
    const link = 'https://buymeacoffee.com/olivergg'
    window.open(link, '_blank')
  }

  return (
    <div id={styles.continue}>
      <p id={styles.donationText}>{resource('donation')}</p>
      <section id={styles.buttons}>
        <FilledButton id={styles.donate} onClick={openBuyMeACoffee}>
          <h3>{resource('buy_beer')}</h3>
        </FilledButton>
        <Button onClick={finishGame}>
          <h3>{resource('again')}</h3>
        </Button>
      </section>
    </div>
  )
}

export default function useGameEndModal() {
  const resource = useResource(data)

  const modal = useModal({
    title: resource('finished'),
    element: <ContinueModal />,
    id: styles.continueModal,
    closeable: false,
    centered: true,
  })

  return modal
}

import useResource from '../../hooks/resource.hooks'
import data from './settings.resource.json'
import styles from './Settings.module.css'
import LanguageSelect from '../../components/Select/Selects/LanguageSelect'
import ThemeSelect from '../../components/Select/Selects/ThemeSelect'
import { useModal } from '../../hooks/modal.hooks'
import GameSelect from '../../components/Select/Selects/GameSelect'

function Settings() {
  const resource = useResource(data)

  return (
    <div id={styles.settings}>
      <section>
        <h4>{resource('language')}</h4>
        <LanguageSelect closeable />
      </section>
      <section>
        <h4>{resource('mode')}</h4>
        <GameSelect closeable />
      </section>
      <section>
        <h4>{resource('theme')}</h4>
        <ThemeSelect />
      </section>
      {/*<section>
        <h4>{resource('spiciness')}</h4>
        <SpicinessSelect />
      </section>*/}
    </div>
  )
}

export default function useSettingsModal() {
  const resource = useResource(data)

  return useModal({
    title: resource('settings'),
    element: <Settings />,
    id: 'settings',
  })
}

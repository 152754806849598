import { Spice } from '../config/spiciness.config'
import prompts from '../data/prompts.resource.json'
import { Language } from '../providers/LocaleProvider'

export interface Prompt {
  spiciness: number
  content: string
}

// Update this eventually to get prompts from backend/external resource
export async function getPrompts(language: Language, spiciness?: Spice) {
  const actual = prompts as Record<Language, Prompt[]>

  return new Promise(resolve => {
    setTimeout(() => {
      resolve(actual[language] as Prompt[])
    }, 500)
  }) as Promise<Prompt[]>
}

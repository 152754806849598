import styles from './Loader.module.css'

interface LoaderProps {
  text?: string
}

export default function Loader({ text = '' }: LoaderProps) {
  return (
    <div className={styles.loading_container}>
      <div className={styles.lds_ring}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {text && <p>{text}</p>}
    </div>
  )
}

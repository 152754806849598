import './App.css'
import GameProvider from './providers/GameProvider'
import Game from './components/Game/Game'
import Footer from './layout/Footer/Footer'
import ModalProvider from './providers/ModalProvider/ModalProvider'
import LocaleProvider from './providers/LocaleProvider'
import ThemeProvider from './providers/ThemeProvider'

function App() {
  return (
    <ThemeProvider>
      <GameProvider>
        <LocaleProvider>
          <ModalProvider>
            <div className="App">
              <div id="content">
                <Game />
                <Footer />
              </div>
            </div>
          </ModalProvider>
        </LocaleProvider>
      </GameProvider>
    </ThemeProvider>
  )
}

export default App

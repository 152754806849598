import Player from '../../../../../../classes/Player.class'
import { FlatButton } from '../../../../../Button/Button'
import './PlayerListItem.css'
import { useContext } from 'react'
import { GameContext } from '../../../../../../providers/GameProvider'
import BsErase from '../../../../../../icons/Erase.icon'

interface PlayerListItemProps {
  player: Player
}

export default function PlayerListItem({ player }: PlayerListItemProps) {
  const { removePlayer } = useContext(GameContext)!

  function remove() {
    removePlayer(player)
  }

  return (
    <div role="listitem" className="playerListItem">
      <h3 className="playerName">{player.name}</h3>
      <FlatButton className="remove" onClick={remove}>
        <BsErase />
      </FlatButton>
      <Backdrop />
    </div>
  )
}

function Backdrop() {
  const backdropString = 'RYGGAR RYGGAR '.repeat(80)

  return (
    <div className="backdrop">
      <p>{backdropString}</p>
    </div>
  )
}

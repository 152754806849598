import { useContext } from 'react'
import styles from './Start.module.css'
import { GameContext } from '../../providers/GameProvider'
import useResource from '../../hooks/resource.hooks'
import { useModal, useModalManager } from '../../hooks/modal.hooks'
import data from './Start.resource.json'
import useGameHandler from '../../hooks/gameHandler.hooks'
import Button, { FilledButton } from '../../components/Button/Button'
import Switch from '../../components/Switch/Switch'
import useSettingsModal from '../Settings/Settings.modal'
import not from '../../utils/not.util'

function Start() {
  const { start, canStart, setAccepted, accepted } = useContext(GameContext)!
  const { handler } = useGameHandler()
  const resource = useResource(data)
  const { close } = useModalManager()
  const { show } = useSettingsModal()

  function play() {
    if (!handler) return

    start()
    close()
  }

  console.log(canStart, accepted)

  return (
    <div id={styles.content}>
      <div id={styles.message}>
        <p>{resource('change')}</p>
        {/* <p>{resource('options')}</p> */}
        <p>{resource('freeplay')}</p>
      </div>

      <div id={styles.bottom}>
        <Switch
          id={styles.accept}
          value={accepted}
          onToggle={setAccepted}
          label={resource('accept')}
        />
        <FilledButton
          bold
          id={styles.confirm}
          disabled={not(accepted || !canStart)}
          onClick={play}>
          {resource('start')}
        </FilledButton>
        <Button bold onClick={show}>
          {resource('open_settings')}
        </Button>
      </div>
    </div>
  )
}

export default function useStartModal() {
  const resource = useResource(data)

  return useModal({
    title: resource('title'),
    element: <Start />,
    id: styles.start,
  })
}

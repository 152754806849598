import Games from '../../../config/game.config'
import Select, { Closeable, SelectOption } from '../Select'
import useGameHandler from '../../../hooks/gameHandler.hooks'
import { useModalManager } from '../../../hooks/modal.hooks'

export default function GameSelect({ closeable }: Closeable) {
  const { handler, setHandler } = useGameHandler()
  const { close } = useModalManager()

  function update(name: string) {
    setHandler(name)
    closeable && close()
  }

  const options = Object.values(Games).map(
    game =>
      new SelectOption(
        () => <>{game.name}</>,
        game.name,
        game.name === handler?.name,
        update
      )
  )

  return <Select items={options} fallback="Välj ett spelläge" />
}

import Player from '../../../../classes/Player.class'
import { Prompt } from '../../../../services/Prompt.service'
import styles from './PromptView.module.css'

interface PromptViewProps {
  prompt: Prompt
  pair: [Player, Player]
  next: () => void
}

export default function PromptView({ prompt, next, pair }: PromptViewProps) {
  const [player1, player2] = pair

  return (
    <article role="button" id={styles.container} onClick={next}>
      <section id={styles.players}>
        <p className={styles.player}>{player1.name}</p>
        <p className={styles.player}>{player2.name}</p>
      </section>
      <section id={styles.prompt}>
        <h3>{prompt && prompt.content}</h3>
      </section>
    </article>
  )
}

import useResource from '../../hooks/resource.hooks'
import styles from './Exit.module.css'
import { useModal, useModalManager } from '../../hooks/modal.hooks'
import data from './Exit.resource.json'
import { useContext } from 'react'
import { GameContext } from '../../providers/GameProvider'
import Button, { FilledButton } from '../../components/Button/Button'

function Exit() {
  const resource = useResource(data)
  const { finish } = useContext(GameContext)!
  const { close } = useModalManager()

  function exit() {
    finish()
    close()
  }

  return (
    <div id={styles.exit}>
      <div id={styles.message}>
        <p>{resource('go_back')}</p>
      </div>
      <div id={styles.buttons}>
        <FilledButton bold id={styles.stay} onClick={close}>
          {resource('stay')}
        </FilledButton>
        <Button bold onClick={exit}>
          {resource('exit')}
        </Button>
      </div>
    </div>
  )
}

export default function useExitModal() {
  const resource = useResource(data)

  return useModal({
    title: resource('title'),
    element: <Exit />,
    id: 'settings',
    centered: true,
  })
}

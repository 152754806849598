import BsCheck from '../../icons/Switch.icon'
import Button from '../Button/Button'
import styles from './Switch.module.css'

interface CheckProps {
  checked: boolean
}

export default function Check({ checked }: CheckProps) {
  return (
    <Button data-checked={checked} noheight className={styles.check}>
      {checked && <BsCheck />}
    </Button>
  )
}

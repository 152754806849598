import { useCallback, useEffect, useMemo, useState } from 'react'

type SetStored = (value: string) => void

export function useStored(key: string): {
  stored: string | undefined
  setStored: SetStored
}
export function useStored(
  key: string,
  fallback: string
): { stored: string; setStored: SetStored }
export function useStored(
  key: string,
  fallback?: string
): { stored: string; setStored: SetStored } {
  const [state, setState] = useState<string | undefined>(fallback)

  const setStored = useCallback(
    (value: string) => {
      setState(value)
      localStorage.setItem(key, value)
    },
    [key]
  )

  useEffect(() => {
    let item = localStorage.getItem(key)

    if (!item && !fallback) return

    if (!item) {
      item = fallback!
      localStorage.setItem(key, item)
    }

    setStored(item)
  }, [key, fallback, setStored])

  const stored = useMemo(() => {
    if (!state && fallback) return fallback

    return state as string
  }, [state, fallback])

  return { stored, setStored }
}

import { useContext } from 'react'
import styles from './Tutorial.module.css'
import { GameContext } from '../../../providers/GameProvider'

export default function Tutorial() {
  const { setSeenTutorial } = useContext(GameContext)!

  return (
    <div id={styles.tutorial} onClick={setSeenTutorial}>
      <div id={styles.container}>
        <h3 className={styles.blink}>Tryck för att visa nästa fråga.</h3>
        <p className={styles.blink}>
          Spelet fortsätter tills sista frågan har visats.
        </p>
      </div>
    </div>
  )
}

import { useContext } from 'react'
import { GameContext } from '../providers/GameProvider'

export default function useGameManager() {
  const { setHandler } = useContext(GameContext)!

  return { setHandler }
}

export function usePlayers() {
  const { players } = useContext(GameContext)!

  return players
}
